body {
  min-height: 100vh;
  background-color: #252422;
  font-family: 'Roboto', 'Helvetica', Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Dosis', 'Helvetica', Arial, sans-serif;
  font-weight: 600;
}

.app-logo {
  width: 30px;
  height: 30px;
}

.bg-gradient {
  background: linear-gradient(to bottom left, #64AD1B, #B1D7B4);
}

.flex-1 {
  flex: 1;
}

li.d-flex>i.fa.fa-check-circle {
  padding-top: 3px;
}

.preview-image-small {
  width: 100%;
  max-width: 280px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.info-text {
  margin-bottom: 20px;
}

.info-text p {
  font-weight: bold;
}

.app-badge {
  width: 100%;
  max-width: 280px;
}

.app-badge:first-child {
  margin-right: 20px;
}

.img-fluid {
  max-width: 80%;
  height: auto;
}

@media (max-width: 1200px) {
  .app-badge:first-child {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  ul {
    font-size: 1.1em;
  }
}